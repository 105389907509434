$blue_egerie: #274058;
$orange_egerie: #e94e1b;
$white_egerie: #fff;
$success_egerie: #198754;
$error_egerie: #ffbabb;
$field_egerie: #f5f5f5;
.metric-widget {
	.metric {
		display: flex;
		align-items: center;
		border-radius: 0.5em;
		display: flex;
		flex-direction: column;
	
		.title {
			width: 100%;
			height: fit-content;
			margin-top: 30px;
			font-weight: 700;
			font-size: 36px;
			line-height: 44px;
			text-align: center;
		}

		.description {
			width: 100%;
			height: fit-content;
			font-weight: 500;
			font-size: 24px;
			line-height: 29px;
			text-align: center;
			margin-top: 20px;
		}
		svg {
			color: $blue_egerie;
		}
	}
}
