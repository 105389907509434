.finalPage {
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.heroContainer {
		padding: 30px 40px;
		background-position: center;
		background-repeat: no-repeat;
		width: 60%;
		background-size: cover;
		color: #222;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.title {
			font-size: 36px;
			font-weight: 700;
			text-align: center;
			margin-bottom: 30px;
		}

		.description {
			font-size: 20px;
			font-weight: 500;
			text-align: center;
			margin-top: 16px;
			width: 60%;
		}

		.finalHeroImage {
			width: 60%;
			height: auto;
		}
	}

	.formContainer {
		margin-top: 10px;
		width: 60%;

		label {
			color: #222 !important;
		}

		.MuiFormControl-root {
			width: 100%;
			margin-top: 16px;
		}

		.MuiInputBase-input {
			color: #222;
		}

		.MuiInputBase-root {
			margin-top: 16px;
			width: 100%;
		}

		.checkboxContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			margin-top: 16px;
			width: 100%;

			.label {
				color: #222;
				width: 100%;
				margin-left: 8px;
				font-size: 14px;
			}

			.link {
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.MuiButtonBase-root {
			padding: 0;
		}

		.buttonContainer {
			display: flex;
			width: 100%;
			.sendButton {
				margin-left: auto;
				height: 40px;
				padding: 4px 12px;
				margin-top: 16px;
			}
		}
	}
}

.referAFriendButton {
	height: 48px;
	background-color: #545454;
	color: white;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	line-height: 17px;
	border: 0;
	font-weight: 700;
	font-size: 14px;
	cursor: pointer;
	transition: opacity 0.5s ease;
	margin-top: 20px;

	.shareIcon {
		height: 18px;
		margin-right: 6px;
	}

	.shareText {
		line-height: 24px;
		margin-top: 2px;
	}
}