$blue_egerie: #274058;
$orange_egerie: #e94e1b;
$white_egerie: #fff;
$success_egerie: #198754;
$error_egerie: #FFBABB;
$field_egerie : #f5f5f5;
.textarea-question{
  p{
    color: $blue_egerie;
    font-size: 0.8em;
    padding: 0 1em;
   }
  .textarea{
    border: 1px solid gray;
    padding: 0 0.5em;
    display: flex;
    align-items: center;
    border-radius: 0.5em;
    svg,label{
      color: $blue_egerie
    }
    textarea{
      margin: 0.5em;
      padding: 0.5em;
      border:none;
      display: flex;
      min-height: 2em;
      min-width: 50%;
      width: 100%;
      font-size: inherit;
      &::placeholder {
        color: $blue_egerie;
      }
    }
  }
}