$blue_egerie: #274058;
$orange_egerie: #e94e1b;
$white_egerie: #fff;
$success_egerie: #198754;
$error_egerie: #ffbabb;
$field_egerie: #f5f5f5;
.radio-question {
	p {
		color: $blue_egerie;
		font-size: 0.8em;
		padding: 0 1em;
	}
	.radio {
		padding: 0 0.5em;
		display: flex;
		align-items: center;
		border-radius: 0.5em;
		label {
			background-color: white;
			color: #222;
			font-weight: 700;
			font-size: 24px;
			line-height: 29px;
			margin-bottom: 10px;
		}
    #radiogroupquestions {
      margin-top: 20px;
    }
		svg {
			color: $blue_egerie;
		}

    .MuiFormControlLabel-label {
      font-size: 20px;
    }
	}
}
