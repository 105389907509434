.offersPage {
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.seeResultsContainer {
		height: 100%;
		width: 80%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.withoutOffers {
			color: #222;
			text-decoration: underline;
			cursor: pointer;
			font-size: 16px;
			margin-top: 4px;
		}
	}

	.heroContainer {
		padding-top: 40px;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		background-size: cover;
		color: #222;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.titleContent {
			font-size: 32px;
			font-weight: 600;
			text-align: center;
			margin-bottom: 20px;
		}

		.title {
			font-size: 36px;
			font-weight: 700;
			text-align: center;
			margin-bottom: 20px;
		}

		.description {
			font-size: 20px;
			font-weight: 500;
			text-align: center;
		}
	}

	.seeResults {
		height: 48px;
		background-color: #0d8bff;
		color: white;
		width: 160px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		line-height: 17px;
		border: 0;
		font-weight: 700;
		font-size: 14px;
		cursor: pointer;
		transition: opacity 0.5s ease;
		margin-top: 20px;

		&.disabled {
			background-color: rgb(177, 176, 176);
			color: #222;
		}
	}

	.offerContainer {
		margin-top: 20px;
		width: 95%;
		display: flex;
		flex-wrap: wrap;
	}

	.error-text {
		margin-top: 6px;
		margin-bottom: 6px;
		font-size: 14px;
		line-height: 14px;
		color: red;
	}
}