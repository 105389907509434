.offer-item {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.85);

	padding-left: 5px;
	padding-right: 5px;
	border-radius: 8px;
	border: 1px solid grey;
	box-sizing: border-box;
	flex: 0 1 calc(50% - 20px); // Yüzde 50 genişliği direkt olarak belirtiyoruz.
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	height: 140px;

	&:not(:nth-child(2n)) {
		// 2'nin katı olanlar hariç her öğeye uygulanır.
		margin-right: 20px; // Sağdaki öğe için 20px aralığı ekliyoruz.
	}

	input[type="checkbox"] {
		margin-right: 10px;
	}


	.offerImage {
		width: 100%;
		height: 100px;
		object-fit: cover;
		display: flex;
		border-radius: 16px;
		margin-right: 10px;
		max-width: 100px;
		padding: 5px;
	}

	.offer-info {
		display: flex;
		flex-direction: column;
		padding: 0 2px;
		width: 100%;
		height: 100%;
		margin: 2px;
		justify-content: center;

		.offerTitle {
			color: #222;
			font-weight: 800;
			font-size: 18px;
			line-height: 18px;
			text-align: start;
			margin: 0;
		}

		.offerText {
			margin-top: 12px;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			text-align: start;
			color: #666;
		}
	}
}

// Media Query for Mobile
@media (max-width: 576px) {
	.offer-item {
		flex: 0 1 100%;
		margin-right: 0;
		height: auto !important;

		&:not(:nth-child(2n)) {
			// 2'nin katı olanlar hariç her öğeye uygulanır.
			margin-right: 0px; // Sağdaki öğe için 20px aralığı ekliyoruz.
		}

		.offerTitle {
			font-size: 14px !important;
			line-height: 18px !important;
		}

		.offerText {
			margin-top: 4px !important;
			font-size: 10px !important;
			line-height: 14px !important;
		}
	}

}

.checked {
	box-shadow: 0 5px 15px rgba(0, 128, 0, 0.89);  // Daha koyu yeşil renkte gölge
}