.homePage {
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.heroContainer {
		background-image: url("https://joy-leads.s3.eu-central-1.amazonaws.com/rs/banner.png");
		height: 600px;
		background-position: center;
		background-repeat: no-repeat;
		width: 100%;
		background-size: cover;
		color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.title {
			font-size: 48px;
			font-weight: 700;
			text-align: center;
			margin-bottom: 20px;
		}

		.description {
			font-size: 24px;
			font-weight: 500;
			text-align: center;
		}

		.buttons {
			display: flex;
			flex-direction: row;
			margin-top: 50px;
			width: 50%;

			.bannerStartSurvey {
				height: 48px;
				background-color: #ff7700;
				color: white;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				line-height: 17px;
				border: 0;
				font-weight: 700;
				font-size: 14px;
				cursor: pointer;
				transition: opacity 0.5s ease;
				margin-top: 20px;
			}
		}

		@media (min-width: 576px) {
			.buttons {
				width: 25%;
			}
		}
	}

	.infoContainer {
		width: 100%;
		background-color: white;
		color: #222222;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 60%;
		margin: 0 auto;
		padding-top: 30px;
		margin-top: 50px;
		padding-bottom: 80px;

		.title {
			font-size: 48px;
			font-weight: 700;
			text-align: center;
			margin-bottom: 20px;
		}

		.description {
			font-size: 24px;
			font-weight: 500;
			text-align: center;
		}

		.buttons {
			display: flex;
			flex-direction: row;
			margin-top: 50px;

			.surveyLink {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px 24px;
				border-radius: 8px;
				background-color: #222222;
				font-weight: 600;
				font-size: 14px;
				cursor: pointer;
				color: white;

				&.info {
					background-color: white;
					color: #222222;
					margin-left: 24px;
				}
			}
		}
	}

	.cardContainer {
		display: flex;
		flex-direction: row;
		margin: 80px 0;
		align-items: center;
		justify-content: center;

		.card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 240px;
			height: 240px;
			border-radius: 16px;

			box-shadow: 0 2px 4px #0000001a, 0 8px 16px #0000000d;
			-webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1),
				0px 8px 16px rgba(0, 0, 0, 0.05);
			-moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1),
				0px 8px 16px rgba(0, 0, 0, 0.05);

			margin-left: 36px;

			&:first-of-type {
				margin-left: 0;
			}

			.cardBadge {
				width: 64px;
				height: 64px;
				background-color: #ff7302;
				color: white;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 24px;
			}

			.cardText {
				color: #222;
				font-weight: 800;
				font-size: 24px;
				line-height: 29.5px;
				text-align: center;
				word-wrap: break-word;
				padding: 30px 20px;
				margin: 0;
			}
		}
	}

	.startContainer {
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		margin-top: 20px;
		align-items: center;
		justify-content: center;
		width: 60%;

		img {
			&:first-of-type {
				padding: 30px 0;
			}
		}

		.arrowImage {
			width: 240px;
			height: 110px;
		}

		.secondImage {
			width: 652px;
			height: 430px;
		}

		.title {
			padding-bottom: 18px;
			color: #222;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			width: 75%;
			margin-top: 30px;
		}

		.description {
			padding-bottom: 14px;
			color: #222;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			margin-top: 30px;
			width: 75%;
		}

		.startSurvey {
			height: 48px;
			background-color: #ff7700;
			color: white;
			width: 75%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 8px;
			line-height: 17px;
			border: 0;
			font-weight: 700;
			font-size: 14px;
			cursor: pointer;
			transition: opacity 0.5s ease;
			margin-top: 20px;
		}

		.legalText {
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			margin: 0;
			color: #222;
			padding-top: 15px;
			width: 75%;

		}
	}
}