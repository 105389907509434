.surveyPage {
	padding-bottom: 80px;

	.sv_progress-buttons__container-center {
		width: 71% !important;
	}

	.sv_progress-buttons__list li {
		width: 75px !important;
		pointer-events: none !important;
	}

	/*   .sv_progress-buttons__list {
        padding-left: 0 !important;
    } */
	#form_survey form .sv-container-modern ul li:after {
		width: 69%;
		margin-left: 2px;
	}
	.sv_progress-buttons__list li:after {
		margin-left: 2px;
	}
}
