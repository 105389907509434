$blue_egerie: #274058;
$orange_egerie: #e94e1b;
$white_egerie: #fff;
$success_egerie: #198754;
$error_egerie: #FFBABB;
$field_egerie : #f5f5f5;
.imagepicker-question{
  p{
    color: $blue_egerie;
    font-size: 0.8em;
    padding: 0 1em;
   }
  .imagepicker{
    display: flex;
    flex-direction: column;
    label{
      display: none;
    }
    ul{
      overflow: hidden;
      display: flex;
      justify-content: center;
      li{
        width: 30%;
        cursor: pointer;
        transition: all 0.5s ease-out;
        margin: 0 0.5em;
        .icons{
          display: flex;
          flex-direction: column;
          i{
            padding: 0.2em;
          }
        }
        .labelin{
          background-color: $orange_egerie;
          opacity: 0.8;
          color: $white_egerie;
          .icons{
            color: $white_egerie;
          }
        }
        .labelbelow{
          color: $blue_egerie;
          .icons{
            color: $orange_egerie;
          }
        }
        &:hover{
          transform: scale(1.08);
        }
      }
    }
  }
}